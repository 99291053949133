const inputFields = ['name', 'email', 'subject', 'body'];
const makeDirtyElement = (HTMLElement) => HTMLElement.setAttribute('dirty', true);

inputFields.forEach((id) => {
  document.getElementById(id).addEventListener('blur', (e) => makeDirtyElement(e.target));
});

document.querySelector('#submit').addEventListener('click', () => {
  inputFields.forEach((id) => makeDirtyElement(document.getElementById(id)));
});

const sendMail = firebase.functions().httpsCallable('landing-sendMail');
const form = document.getElementsByTagName('form')[0];

form.addEventListener('submit', (event) => {
  event.preventDefault();

  const name = event.target.elements['name'].value;
  const email = event.target.elements['email'].value;
  const subject = event.target.elements['subject'].value;
  const body = event.target.elements['body'].value;

  const submit = event.target.querySelector('#submit');
  const submitText = submit.querySelector('span');
  const submitIcons = submit.querySelectorAll('img');
  submit.disabled = true;

  sendMail({
    name,
    email,
    subject,
    body,
  }).then(() => {
    const prevSubmitText = submitText.textContent;
    submitText.textContent = 'Sent';
    submitIcons[0].classList.toggle('hidden', true);
    submitIcons[1].classList.toggle('hidden', false);

    alert('Thank you for reaching out! Someone from Cardivore will be in touch shortly.');
    clearForm();

    setTimeout(() => {
      submitText.textContent = prevSubmitText;
      submitIcons[0].classList.toggle('hidden', false);
      submitIcons[1].classList.toggle('hidden', true);
      submit.disabled = false;
    }, 2000);
  });
});

const clearForm = () => {
  inputFields.forEach((id) => document.getElementById(id).setAttribute('dirty', false));
  form.reset();
};
